<template>
    <div class="question">
      <Header></Header>
        <div :style="{ minHeight: minHeight - 330 + 'px' }">
            <div id="question">
                <div class="title">
                    <div></div>
                </div>
                <div  class="questionList">
                    <span>常见问题</span>
                    <el-collapse v-model="activeNames" accordion>
                        <div v-for="(item, index) in questionList" :key="index">
                            <el-collapse-item  slot="title" :name="'num'+index">
                                <template  slot="title">
                                    <div :class="['questionList_title','num'+index == activeNames? 'active':'']">
                                        <i class="i-circle"></i> {{item.title}}
                                    </div>
                                </template>
                                <div :class="['questionList_text','num'+index == activeNames? 'active':'']">答：{{item.text}}</div>
                            </el-collapse-item>
                        </div>
                    </el-collapse>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    export default {
        name: "Question",
        data(){
            return {
                questionList:[
                    {title:'手机端按钮太小了点不到怎么办？',text:'在手机端可以使用双指放大功能，能够像放大图片一样对游戏画面进行缩放，以便精准点击操作。'},
                    {title:'玩《传奇》系列游戏时角色卡在地图里动不了了怎么办？',text:'请前往对应游戏的官方网站联系客服人员，重置角色坐标即可。也可以通过点击页面上的【反馈】按钮来提交错误信息，由于重置坐标需要角色在线，建议留下联系方式以便沟通。'},
                    {title:'游戏画面模糊、操作延迟过高怎么办？',text:'传奇云游戏采用智能动态码率技术，会根据玩家的网络情况自动调整视频流来最大程度上保证画面的流畅性，画面突然模糊一般是由于网络波动造成的。建议游玩时确保您的网络畅通，手机APP用户可以点击屏幕左上角【设置】中的【网络检测】按钮来测试当前网络速度。'},
                    {title:'排队时间过长怎么办？',text:'高峰期排队人数较多，建议更换其他游戏进行体验，在排队界面可以看到当前排队的人数。'},
                    {title:'点击游戏内的充值按钮没有反应，我该如何充值？',text:'网页版点击页面右侧的【充值】按钮，在弹出的页面中进行快速充值。手机端点击画面中的【充值】虚拟按键，在弹出的页面中进行快速充值。'},
                    {title:'卡在游戏主界面无法进入游戏？',text:'频繁打开同一款游戏可能会出现此问题，这是由于之前启动的游戏尚未完全关闭，请稍作等待后刷新页面重新启动游戏。'},
                    {title:'如何对自己的角色释放增益技能？',text:'在有锁定目标的情况下无法对自己释放技能，请先击杀已经处于锁定状态下的敌对目标，或者移动角色至其他区域重置锁定状态。我们接下来会进一步优化操作，提供更好的游戏体验。'},
                    {title:'如何保存我的游戏存档以及游戏设置？',text:'网页版点击页面右侧的【存档】按钮进行存档，手机端在画面左上角的菜单栏中点击【存档】按钮。再次启动游戏时会自动加载最新的云端存档，无需手动进行操作。在网页端的【个人中心】界面可以查询自己的存档记录。'},
                    {title:'游戏中显示服务器强制退出，显示游戏关闭怎么办？',text:'可能是网络波动导致与服务器中断连接，建议首先通过【网络检测】功能检查您的网络情况。如果网络正常的情况下频繁退出，请点击页面中的【反馈】按钮提交错误信息。'},
                    {title:'为什么某些手机打开游戏会画面变形？',text:'目前传奇云游戏适配大多数通用设备，一些特殊机型在运行游戏时可能会出现画面变形的情况，遇到此问题请通过【反馈】功能提交您的机型数据，我们会不断优化画面适配来为所有玩家提供完善的游戏体验。'},
                ],
                activeNames: 'num0',
                minHeight: 0, //内容高度
            }
        },
        mounted() {
            // 监听屏高变化，撑开高度
            this.minHeight = document.documentElement.clientHeight;
            window.onresize = () => {
                this.minHeight = document.documentElement.clientHeight;
            };
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
    .question {
        #question{
            >.title{
                height: 480px;
                background-color: #2F333D;
                >div{
                    width: 1120px;
                    height: 480px;
                    margin: 0 auto;
                    background-size: cover;
                    background-image: url("../../../assets/images/officialWebsite/bg-question-title.png");
                }
            }
            .questionList{
                width: 1120px;
                margin: 0 auto;
                padding-bottom: 50px;
                >span{
                    display: block;
                    font-size: 24px;
                    padding-top: 75px;
                    padding-bottom: 25px;
                    font-weight: bold;
                    color: #000000;
                }
                .el-collapse{
                    border-top: none;
                    border-bottom: none;

                    .questionList_title{
                        font-size: 20px;
                        font-weight: 600;
                        display: flex;
                        >.i-circle{
                            width: 8px;
                            height: 8px;
                            display: inline-block;
                            margin-top: 18px;
                            margin-right: 16px;
                            border-radius: 50%;
                            background: #ADADB0;
                        }
                    }
                    .questionList_title.active{
                        color: #FA6400;
                    }
                    .questionList_text{
                        padding-left: 24px;
                        font-size: 18px;
                        color: #606062;

                    }
                    .questionList_text.active{
                        padding-bottom: 24px;
                        border-bottom: 1px solid rgba(250, 216, 161, 0.5);
                    }
                    /deep/.el-collapse-item__wrap{
                        border-bottom: none;
                    }
                    /deep/.el-collapse-item__header{
                        height: 60px;
                        border-bottom: none;
                    }
                }
            }
        }
    }


@media screen and (max-width:1200px) {
    .question #question{
        .title{
            width: 100%;
        }
        .questionList{
            width: 100%;
            padding: 0 20px;
            .questionList_title{
                line-height: 20px;
            }
        }
    }
}


</style>
